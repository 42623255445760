

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PaystackPop from "@paystack/inline-js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import TermsAndConditions from "./TermsAndConditions";
import { Context } from "./Contex";
import axios from "axios"

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  padding-top: 100px;
  padding-bottom:100px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  // color: #1e3a8a;
  color:rgba(0,0,255,0.5);
  font-size: 2rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline:none;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline:none;
`;

const AmountDisplay = styled.p`
  font-size: 16px;
  color: #444;
  margin: 10px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  outline:none;
`;

const Button = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width:100%;
  margin-top:20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const TrainingRegistrationPage = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [courseCode, setCourseCode]=useState(0)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    state: "",
    country: "",
    address: "",
    course: "",
    agreedToTerms: false,
    amountPaid: "",
  });

  const [error, setError] = useState("");

 

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const [course,setCourse]=useState("")
  
  useEffect(()=>{
    if(course==="Frontend Development"){
      setAmount(50000)
      setCourseCode(1)
    }else if(course==="Graphics Design"){
      setAmount(50000)
      setCourseCode(2)
    }else if(course==="Product Design"){
      setAmount(50000)
      setCourseCode(3)
    }
    else if(course==="Backend Development"){
      setAmount(50000)
      setCourseCode(4)
    }
    else if(course==="Computer Appreciation and Basics"){
      setAmount(30000)
      setCourseCode(5)
    }
    else if(course==="Content Creation, video Editing and Social media management"){
      setAmount(50000)
      setCourseCode(6)
    }
    else if(course==="Digital Marketing"){
      setAmount(50000)
      setCourseCode(6)
    }
    else if(course==="E-Commerce and Dropshipping"){
      setAmount(30000)
      setCourseCode(6)
    }

  },[course,courseCode])

  useEffect(()=>{
    if(course!==''||courseCode!==0){
      window.location.reload()
    }
  },[])


  useEffect(() => {
    setFormData({ ...formData, amountPaid: amount });
  }, [amount]);


  // Function to handle form submission to your PHP endpoint
const handleSubmit = async (paymentReference) => {
  const loadingAlert = Swal.fire({text:"Please Wait...",allowOutsideClick:false});
  Swal.showLoading();   
  const dataToSubmit = {
    ...formData,
    paymentReference,
  };

  try {
    const response = await fetch("https://hotsalesng.com/api3/email_endpoint.php", { // Update this URL
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSubmit),
    });

    if (response.ok) {
      Swal.fire({
        icon: "success",
        text: "Registration successful, one of our representatives shall get back to you soon. You can request to join the Group. Thanks",
        allowOutsideClick:false
      }).then(() => {
        Swal.fire({text:"Redirecting you to the group...",timer:5000,allowOutsideClick:false})
        Swal.showLoading();
        window.location = "https://chat.whatsapp.com/CI8Apo6ZEmaEs9di50ajTR";
      });
      navigate("/")
 
    } else {
      Swal.fire({ icon: "error", text: "Form submission failed. Please try again later." });
    }
  } catch (error) {
    Swal.fire({ icon: "error", text: `Error: ${error.message}` });
  }finally{
    loadingAlert.close();
  }
};


  const payWithPaystack = (e) => {
    e.preventDefault();

    if (!formData.agreedToTerms) {
      Swal.fire({ text: "You must agree to the terms and conditions." });
      return;
    }

    Swal.fire({
      text: "Please wait...",
      allowOutsideClick: false,
    });
    Swal.showLoading();

    const paystack = new PaystackPop();
    paystack.newTransaction({
      // key: "pk_test_60e1f53bba7c80b60029bf611a26a66a9a22d4e4",
      key:"pk_live_afb3375b9a770a5a332904dcf1a26e77c2a5f170",
      amount: amount * 100,
      email: formData.email,
      firstname: formData.name,
      onSuccess: (transaction) => {
        // Swal.fire({ icon: "success", text: "Payment successful!", showConfirmButton: true, timer: 2000 });
        // handleSubmit(transaction.reference); 
        verifyTransaction(transaction.reference)
        
      },
      onCancel: () => {
        Swal.fire({ icon: "error", text: "Payment cancelled.", showConfirmButton: true });
      },
      onError: (error) => {
        Swal.fire({ icon: "error", text: `Payment failed: ${error.message}`, showConfirmButton: true });
      },
    });
  };


  
  const verifyTransaction = async (paymentReference) => {
    const loadingAlert = Swal.fire({
      title: "Confirming payment",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
  
    Swal.showLoading();
  
    try {
      // Include your secret key in headers
      const response = await axios.get(`https://api.paystack.co/transaction/verify/${paymentReference}`, {
        headers: {
          // Authorization: `Bearer sk_test_2c7610bd616abcaeaf6a002ad8d96f994d8ff723`
          Authorization: `Bearer sk_live_a4b90817cbddf701c55ab1936d43ef666a6dc220`
        }
      });

    
  
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          text: "Payment successful!",
          showConfirmButton: true,
          timer: 2000
        });

        handleSubmit(paymentReference); 
      } else {
        Swal.fire({
          icon: "error",
          text: `${response.data.message}`,
          showConfirmButton: true
        });
      } 
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || 'Error verifying payment!',
        showConfirmButton: true
      });
    } finally {
      loadingAlert.close();
    }
  };
  



  const [currencyOption,setCurrencyOption]=useState("")

  const generateOrderRef = () => {
    return 'ORD-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
  };

  const flutterwaveConfig = {
    public_key: "FLWPUBK_TEST-3f4052d4a8b6e027b460756652b193df-X",
    tx_ref: generateOrderRef(),
    amount: amount/1600,
    currency: 'USD',
    payment_options: 'card,banktransfer',
    customer: {
      email: formData.email,
      name: formData.name,
    },
    customizations: {
      title: 'Digital Premium Tech',
      description: 'Payment for Training',
    },
  };
  
  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
  
  const payWithFlutterWave = () => {
    Swal.fire({
      text: `You are about to pay NGN ${amount}, which is USD ${(amount / 1600).toFixed(2)} in dollars.`,
      icon: "info",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        handleFlutterPayment({
          callback: (response) => {
            if (response.status === "successful") {
              Swal.fire({ icon: "success", text: "Payment successful!" });
              handleSubmit(response.transaction_id);
            }
            closePaymentModal();
          },
          onClose: () => Swal.fire({ icon: "error", text: "Payment cancelled." }),
        });
      } else {
        Swal.fire({ icon: "warning", text: "Payment terminated" });
      }
    });
  };


  


  const handlePayment = (e)=>{
    console.log(currencyOption)
    console.log(courseCode)
   e.preventDefault();
    if(currencyOption==="naira"){
      Swal.fire({text:"Please wait...",timer:4000})
      Swal.showLoading();
      payWithPaystack(e);
    }else if(currencyOption==="usd"&&courseCode===1){
      Swal.fire({text:"Please wait..."})
      Swal.showLoading();
      window.location.href = "https://selar.co/631131"
    }else if(currencyOption==="usd"&&courseCode===2){
      Swal.fire({text:"Please wait..."})
      Swal.showLoading();
      window.location.href = "https://selar.co/01t321"
    }else if(currencyOption==="usd"&&courseCode===3){
      Swal.fire({text:"Please wait..."})
      Swal.showLoading();
      window.location.href = "https://selar.co/31rp08"
    }
  }
 
  const {showTermsAndConditionsUI,setShowTermsAndConditionsUI}=useContext(Context)

  useEffect(() => {
    const reloadPage = sessionStorage.getItem("reloaded");
    if (!reloadPage) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }
  }, []);
  

  return (
    <Container>
      {showTermsAndConditionsUI&&<TermsAndConditions/>}
      <Title>Training Registration</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handlePayment}>
        <FormField>
          <Label>Name</Label>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>Email</Label>
          <Input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>Phone Number</Label>
          <Input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>State</Label>
          <Input type="text" name="state" value={formData.state} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>Country</Label>
          <Input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>Address</Label>
          <Input type="text" name="address" value={formData.address} onChange={handleChange} required />
        </FormField>
        <FormField>
          <Label>Course</Label>
          {/* <Select name="course" value={formData.course} onChange={handleChange} required>
            <option value="">Select a course</option>
            {Object.keys(courses).map((course) => (
              <option key={course} value={course}>
                {course}
              </option>
            ))}
          </Select> */}

          <Select onChange={(e)=>setCourse(e.target.value)} value={course} required>
            <option value="">-- Select a Course -- </option>
            <option value="Frontend Development">Frontend Development</option>
            <option value="Graphics Design">Graphics Design</option>
            <option value="Product Design">Product Design</option>
            <option value="Backend Development">Backend Development</option>
            <option value="Computer Appreciation and Basics">Computer Appreciation and Basics</option>
            <option value="Content Creation, video Editing and Social media management">Content Creation, video Editing and Social media management</option>
            <option value="Digital Marketing">Digital Marketing</option>
            <option value="E-Commerce and Dropshipping">E-Commerce and Dropshipping</option>
          </Select>
          {course!=="" && <AmountDisplay>Course Fee: ₦{amount.toLocaleString()}</AmountDisplay>}
        </FormField>
        <Label style={{fontSize:"small",color:"blue",textDecoration:"underline",cursor:"pointer"}}
        onClick={()=>setShowTermsAndConditionsUI(true)} >Click to read Terms and Conditions</Label><br/><br/>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            name="agreedToTerms"
            checked={formData.agreedToTerms}
            onChange={handleChange}
            required
            style={{cursor:"pointer"}}
          />
          <Label >I agree to the terms and conditions</Label>
       
        </CheckboxContainer>
        
        {/* <Label style={{fontSize:"small",color:"blue",textDecoration:"underline",cursor:"pointer"}}  >Click to read Privacy Policy</Label><br/><br/> */}
        <Select className='OrderSummaryPageSelect' onChange={(e)=>setCurrencyOption(e.target.value)} value={currencyOption} required>
  <option value="">--Select payment method and click "Register"--</option>
  <option value="naira">Paystack (Naira) </option>
  {/* <option value="usd">Selar (Naira, USD and Others) +7% Charge Applies</option> */}
</Select>
<br/>

        <Button type="submit">Register</Button>
      </form>
    </Container>
  );
};

export default TrainingRegistrationPage;

