import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import logo from '../Images/logo.jpeg'

const Intro = () => {
  return (
    <Section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Container>
        <Heading>Digital Premium Tech Solutions</Heading>
        <Img src={logo} alt="logo"/>
        <Description>
          Digital Premium Tech Solutions is a cutting-edge technology establishment dedicated to empowering businesses, individuals, and organizations with innovative digital solutions and services. Our mission is to revolutionize industries by leveraging technology and artificial intelligence (AI) to enhance productivity, efficiency, and accessibility. With a diverse range of services, training programs, and proprietary platforms, we aim to create a future where technology is seamlessly integrated into daily operations and personal growth.
        </Description>
      </Container>
    </Section>
  );
};

export default Intro;

// Styled Components
const Section = styled(motion.div)`
  width: 100%;
  padding: 80px 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 70vh;
`;

const Container = styled.div`
  max-width: 800px;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;

color:rgba(0,0,255,0.5);
  margin-bottom: 20px;

`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;

color:#555;

`;

const Img = styled.img`
    width:100px;

`






