  


import React from 'react';
import {
  FaDharmachakra,
  FaMobile,
  FaBullhorn,
  FaUsers,
  FaAssistiveListeningSystems,
  FaMoneyCheck,
  FaDev,
  FaMoneyBill,
  FaRobot,
  FaCloud,
  FaLock,
  FaPenFancy,
  FaBrush
} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import "../CSS2/Gigs2.css";

const Gigs = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/contactus");
  };

  const services = [
    {
      icon: <FaDharmachakra className="OurServiceBodyIcon" />,
      title: "Custom Software and Web Development",
      description: "We offer tailored solutions for websites, web applications, portfolio sites, and more to meet your unique needs."
    },
    {
      icon: <FaMobile className="OurServiceBodyIcon" />,
      title: "Mobile App Development",
      description: "We build intuitive, feature-rich mobile apps for iOS, Android, and other platforms."
    },
    {
      icon: <FaRobot className="OurServiceBodyIcon" />,
      title: "Artificial Intelligence (AI) Integration",
      description: "AI-powered tools to optimize processes and decision-making."
    },
    {
      icon: <FaUsers className="OurServiceBodyIcon" />,
      title: "UI/UX Design",
      description: "Creating visually stunning and user-friendly interfaces to elevate the user experience."
    },
    {
      icon: <FaAssistiveListeningSystems className="OurServiceBodyIcon" />,
      title: "Software Maintenance and Support",
      description: "Ensuring your software stays reliable and up-to-date with ongoing assistance and support."
    },
    {
      icon: <FaBullhorn className="OurServiceBodyIcon" />,
      title: "Digital Marketing",
      description: "Boost your online presence with targeted strategies in SEO, social media, content marketing, and advertising campaigns."
    },
    {
      icon: <FaMoneyCheck className="OurServiceBodyIcon" />,
      title: "E-commerce Solutions",
      description: "Developing powerful e-commerce platforms to help your business grow and succeed online."
    },
    {
      icon: <FaDev className="OurServiceBodyIcon" />,
      title: "API Development",
      description: "Designing and implementing robust APIs for seamless integration between systems."
    },
    {
      icon: <FaMoneyBill className="OurServiceBodyIcon" />,
      title: "Payment Integration",
      description: "Integrating secure payment gateways for smooth financial transactions on your platforms."
    },
   
    {
      icon: <FaCloud className="OurServiceBodyIcon" />,
      title: "Cloud Computing Services",
      description: "Scalable cloud solutions for data storage and management."
    },
    {
      icon: <FaLock className="OurServiceBodyIcon" />,
      title: "Cybersecurity Services",
      description: "Robust security measures to protect businesses from cyber threats."
    },
    {
      icon: <FaPenFancy className="OurServiceBodyIcon" />,
      title: "Content Creation",
      description: "High-quality, engaging content for digital platforms."
    },
    {
      icon: <FaBrush className="OurServiceBodyIcon" />,
      title: "Graphics Design",
      description: "Visually appealing designs for branding and marketing."
    }
  ];

  return (
    <div className="OurServicesWrap">
      <div className="OurServices">
        <div className="OurServicesHeading">
          <h1>Our Services</h1>
        </div>
        <div className="OurServicesBody">
          {services.map((service, index) => (
            <div className="OurService fade-slide-in" key={index}>
              {service.icon}
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
        <div className="ContactUsNowWrap">
          <h1 onClick={handleNavigate}>Contact Us Now for Your Projects</h1>
        </div>
      </div>
    </div>
  );
};

export default Gigs;  
