import React from 'react';
import styled from 'styled-components';
import classified from '../Images/classified.jpg'

// Main Wrapper for the Component
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 5px;
//   background-color: #f7f7f7;
  min-height: 100vh;
//   font-family: 'Arial', sans-serif;
width:100%;
background-image:url(${classified});
 background-position: center;
    position: relative;
    background-size: cover;


      &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.5);
    z-index: 0;
  }

  @media (max-width: 768px) {
    // padding: 15px;
  }
`;



// Header Section
const Header = styled.header`
  text-align: center;
//   color: rgba(0,0,255,0.5);
  margin-bottom: 30px;
  position:relative;

  h1 {
    font-size: 2em;
    font-weight: bold;
    // color: rgba(0,0,255,0.5);
     color: white;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
  }

  p {
    font-size: 1.2em;
    // color: #4a5568;
      color: white;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }
  }
`;

// Section for Features
const Features = styled.section`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
//   gap: 20px;
//   width: 100%;
//   max-width: 1200px;
position:relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Feature Box
const FeatureBox = styled.div`
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }

  h3 {
    font-size: 1.5em;
    color: rgba(0,0,255,0.5);
    margin-bottom: 10px;
  }

  p {
    font-size: 1em;
    color: #4a5568;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

// Call to Action Section
const CallToAction = styled.div`
//   background-color: rgba(0,0,255,0.5);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  max-width: 1000px;
  margin-top: 40px;
  position:relative;

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  button {
    background-color:white;
    border: none;
    color: rgba(0,0,255,0.5);
    font-size: 1.2em;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0,0,255,0.7);
      color:white;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 15px;

    h2 {
      font-size: 1.8em;
    }

    p {
      font-size: 1em;
    }

    button {
      font-size: 1em;
    }
  }
`;

const OnlineClassifiedPlatform = () => {
  return (
    <Wrapper>
      <Header>
        <h1>Our Online Classified Platform</h1>
        <p>Connecting businesses, individuals, and organizations with new opportunities.</p>
      </Header>

      <Features>
        <FeatureBox>
          <h3>Product and Service Listings</h3>
          <p>Greater visibility for businesses and individuals to showcase their products and services.</p>
        </FeatureBox>

        <FeatureBox>
          <h3>Job Postings</h3>
          <p>Employers can post job opportunities, while job seekers can browse and apply for roles.</p>
        </FeatureBox>

        <FeatureBox>
          <h3>Post Sponsorships</h3>
          <p>Users can sponsor their posts to ensure they reach a wider audience with higher rankings.</p>
        </FeatureBox>
      </Features>

      <CallToAction>
        <h2>Ready to Get Started?</h2>
        <p>Whether you're a business, job seeker, or employer, we have something for everyone.</p>
        <button>Join Now</button>
      </CallToAction>
    </Wrapper>
  );
};

export default OnlineClassifiedPlatform;
