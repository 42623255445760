import React from 'react';
import "../CSS2/WhyTrainWithUs.css";
import idea from "../Images/flat-color-icons_idea.png"; // Your icon image
import { useNavigate } from 'react-router-dom';

const WhyTrainWithUs = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/contactus");
    };

    const reasons = [
        "You will get online training from the comfort of your home.",
        "You will get the best teaching ever.",
        "You will get the cheapest price ever.",
        "You will build real-life projects for best learning.",
        "You will get coaching and mentorship for life.",
        "You will have access to tech stars on demand.",
        "You will get a Certificate of Participation.",
        "You will get 10% cash back on referrals.",

    ];

    return (
        <div className='WhyTrainWithUsWrap'>
            <div className='WhyTrainWithUs'>
                <div className='WhyTrainWithUsHeading'>
                    <h1>Why Train with Us?</h1>
                </div>
                <div className='WhyTrainWithUsBody'>
                    {reasons.map((reason, index) => (
                        <div className='WhyTrainWithUsBodyItem' key={index}>
                            <img src={idea} alt="icon" />
                            <p>{reason}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='ContactUsNowWrap' style={{position:'relative'}}>
                <h1 onClick={handleNavigate}>Contact Us Now!</h1>
            </div>
        </div>
    );
};

export default WhyTrainWithUs;
