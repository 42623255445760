
// import React from 'react';
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';  // Social Icons
// import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';  // Contact Icons
// import "../CSS2/Footer2.css";

// const Footer = () => {
//   return (
//     <div className='FooterWrap'>
//       <div className='Footer'>
        
//         {/* Footer Company Info Section */}
//         <div className='FooterColumn'>
//           <h3>About Us</h3>
//           <p>Digital Premium Tech is your trusted partner in tech innovation, offering top-notch solutions for productivity and creativity.</p>
//         </div>

//         {/* Footer Quick Links Section */}
//         <div className='FooterColumn'>
//           <h3>Quick Links</h3>
//           <ul>
//             <li><a href='/'>Home</a></li>
//             <li><a href='/aboutus'>About Us</a></li>
//             {/* <li><a href='/services'>Services</a></li> */}
//             <li><a href='/contactus'>Contact Us</a></li>
//             <li><a href='/privacy-policy'>Privacy Policy</a></li>
//             <li><a href='/terms-of-service'>Terms of Service</a></li>
//           </ul>
//         </div>

//         {/* Footer Social Media Section */}
//         <div className='FooterColumn'>
//           <h3>Follow Us</h3>
//           <div className='FooterSocials'>
//             <a href='https://facebook.com'><FaFacebookF /></a>
//             <a ><FaYoutube /></a>
//             <a ><FaInstagram /></a>
//             <a ><FaLinkedinIn /></a>
//           </div>
//         </div>

//         {/* Footer Contact Section */}
//         <div className='FooterColumn'>
//           <h3>Contact Us</h3>
//           <div className='FooterContact'>
//             <MdEmail />
//             <p>digitalpremiumtech@gmail.com</p>
//           </div>
//           <div className='FooterContact'>
//             <MdPhone />
//             <p>+2347063480314</p>
//           </div>
//           <div className='FooterContact'>
//             <MdLocationOn />
//             <p>Lagos, Nigeria</p>
//           </div>
//         </div>
//       </div>

//       {/* Footer Newsletter Section */}
//       {/* <div className='FooterNewsletter'>
//         <h3>Subscribe to our Newsletter</h3>
//         <form className='NewsletterForm'>
//           <input type='email' placeholder='Enter your email' required />
//           <button type='submit'>Subscribe</button>
//         </form>
//       </div> */}

//       {/* Footer Bottom Section */}
//       <div className='FooterBottom'>
//         <p>&copy; {new Date().getFullYear()} Digital Premium Tech. All rights reserved.</p>
//       </div>
//     </div>
//   );
// }

// export default Footer;




import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';
import "../CSS2/Footer2.css";
import Logo from '../Images/logo.jpeg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className='FooterWrap'>
      <div className='Footer'>
        {/* Footer Company Info Section */}
        <div className='FooterColumn fade-slide'>
    
          <img src={Logo} alt='logo'/>
          <p>Digital Premium Tech Solutions is your trusted partner in tech innovation, offering top-notch solutions for productivity and creativity.</p>
        </div>

        {/* Footer Quick Links Section */}
        <div className='FooterColumn fade-slide'>
          <h3>Quick Links</h3>
          <ul>
            <li><a onClick={()=>navigate('/')}>Home</a></li>
            <li><a onClick={()=>navigate('/aboutus')}>About Us</a></li>
            <li><a onClick={()=>navigate('/contactus')}>Contact Us</a></li>
            <li><a onClick={()=>navigate('/privacypolicy')}>Privacy Policy</a></li>
            <li><a onClick={()=>navigate('/termsandconditions')}>Terms and Conditions</a></li>
          </ul>
        </div>

        {/* Footer Social Media Section */}
        <div className='FooterColumn fade-slide'>
          <h3>Follow Us</h3>
          <div className='FooterSocials'>
            <a href='https://facebook.com'><FaFacebookF /></a>
            <a href='https://youtube.com'><FaYoutube /></a>
            <a href='https://instagram.com'><FaInstagram /></a>
            <a href='https://linkedin.com'><FaLinkedinIn /></a>
          </div>
        </div>

        {/* Footer Contact Section */}
        <div className='FooterColumn fade-slide'>
          <h3>Contact Us</h3>
          <div className='FooterContact'>
            <MdEmail />
            <p>digitalpremiumtech@gmail.com</p>
          </div>
          <div className='FooterContact'>
            <MdPhone />
            <p>+2347063480314</p>
          </div>
          <div className='FooterContact'>
            <MdLocationOn />
            <p>Lagos, Nigeria</p>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className='FooterBottom fade-slide'>
        <p>&copy; {new Date().getFullYear()} Digital Premium Tech. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;

