import React from 'react'
import Hero from './Hero'
import Training from './Training'
import WhyTrainWithUs from './WhyTrainWithUs'
import Gigs from './Gigs'
import Footer from './Footer'
import SpecialOffer from './SpecialOffer'
import OurPartners from './OurPartners'
import OnlineAcademy from './OnlineAcademy'
import Intro from './Intro'
import OnlineClassifiedPlatform from './OnlineClassifiedPlatform'
import IndustryProducts from './OurIndustrySpecificProducts'
import ConclusionSection from './ConclusionSection'


const LandingPage = () => {
  return (
    <div>
      <Hero/>
      {/* <SpecialOffer/> */}
      <Intro/>
      <Gigs/>
      <Training/>
      <WhyTrainWithUs/>
      <OnlineAcademy/>
      <OnlineClassifiedPlatform/>
      <IndustryProducts/>
      <ConclusionSection/>
      <OurPartners/>
    </div>
  )
}

export default LandingPage
